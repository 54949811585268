/* -----------------------------------------------------------------------------

# Tools - Background

----------------------------------------------------------------------------- */

.bg-dark {
  background: $color-text;
}

.bg-light {
  background: $color-gray-light;
}

.bg-primary {
  background: $color-primary;
}

.bg-white {
  background: $color-white;
}

/* Gradients */

.bg-gradient-dark {
  @include linear-gradient($color-text, darken($color-text, $darken));
}

.bg-gradient-light {
  @include linear-gradient($color-white, $color-gray-light);
}

.bg-gradient-blue {
  @include linear-gradient(#2584e0, #0957d9);
}

.bg-gradient-cyan {
  @include linear-gradient(#1ac8e2, #04b2db);
}

.bg-gradient-green {
  @include linear-gradient(#67dbb8, #39cfa2);
}

.bg-gradient-indigo {
  @include linear-gradient(#606cc3, #313fae);
}

.bg-gradient-olive {
  @include linear-gradient(#aacdb5, #85b99e);
}

.bg-gradient-pink {
  @include linear-gradient(#e58cba, #dc60a3);
}

.bg-gradient-sienna {
  @include linear-gradient(#d5bfab, #c4a495);
}

.bg-gradient-violet {
  @include linear-gradient(#9e3ee2, #7421db);
}

/* Advanced backgrounds */

[class*="bg-image-"] {
  overflow: hidden;
  position: relative;
/*  z-index: 0; this was messing with the header main */

  &:before,
  &:after {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    bottom: -1px;
    content: "";
    height: 101%;
    left: -1%;
    position: absolute;
    top: -1px;
    width: 102%;
  }

  &:before {
    z-index: -1;
  }

  &:after {
    z-index: -2;
  }
}

/* Overlays */

.overlay:before {
  background: rgba($color-white, 0.7);
}

.dark.overlay:before {
  background: rgba($color-text, 0.7);
}

@each $name in "01" "02" "03" "04" "05" "06" {
  .overlay-shape-#{$name}:before {
    background: url(media/bg/shape-#{$name}.svg);
  }

  .dark.overlay-shape-#{$name}:before {
    background: url(media/bg/shape-dark-#{$name}.svg);
  }
}

/* Images */

@each $name in "01" "02" "03" "04" "05" "06" "07" "08" {
  .bg-image-#{$name}:after {
    background: url(media/bg/image-#{$name}@2x.jpg);

    @include mq("mobile") {
      background: url(media/bg/image-#{$name}.jpg);
    }
  }
}

.bg-image-hero:after {
  @include linear-gradient-diagonal($color-secondary, $color-primary);
}