.marshall-newsletter-header
{
	padding: 40px 0 20px;
	position: relative;

	img
	{
		display: block;
		margin: auto;
		position: relative;
		width: 60px;
		z-index: 3;
	}
}

.marshall-newsletter-inner
{
	padding: 0 30px;
	padding-bottom: 30px;
	
	text-align: center;
}

.invite-description > p
{
	color: #747474;
}

span.close-newsletter
{
	cursor: pointer;
	height: 25px;
	line-height: 20px;
	position: absolute;
	right: 12px;
	top: 12px;
	width: 20px;
	color: #999;
	transition: all 0.4s ease;

	&:hover
	{
		font-size: 1.2em;
		color: #111;
	}
}

.marshall-newsletter-content
{
	> input
	{
		display: block;
		width: 100%;
	}

	input[type="email"]
	{
		background-color: #ebebeb;
		border: 0 solid;
		line-height: 1.5;
		margin-bottom: 10px;
		padding: 15px;
		text-align: center;
	}
	
	margin: 35px auto auto;
	width: 80%;
	
	.marshall_submit
	{
		background-color: #f61067;
		border: 0 none;
		display: block;
		width: 100%;
		color: #fff;
		cursor: pointer;
		padding: 12px 0;
		transition: width 0.4s ease 0s, border-radius 0.4s;
		position: relative;
		margin: auto;
		
		&:hover
		{
			background-color: #E00A62;
		}
		
		&:after
		{
			background-image: url('/media/tick.png');
			background-size: 100% auto;
			content: "";
			display: block;
			height: 20px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: scale(0);
			width: 20px;
			z-index: 9;
			visibility: hidden;
			opacity: 0;
			margin-top: -10px;
			margin-left: -10px;
			transition: transform 0.4s;
		}
	}
}

.morph-button-modal.open .morph-content
{
	height: 464px;
	margin-top: -232px;
}

.invite-description h2
{
	margin-top: 10px;
	line-height: 1.1em;
}

@keyframes spinnerRotate
{
    from{transform:rotate(0deg);}
    to{transform:rotate(360deg);}
}


.marshall-newsletter-content.mform-submitting .marshall_submit
{
	border-radius: 50% !important;
	height: 46px;
	margin: auto;
	text-indent: -9999px;
	width: 46px !important;
}

.marshall-newsletter-content .marshall_submit::before
{
	opacity: 0;
	visibility: hidden;
	background-color: transparent;
	border: 2px dashed #fff;
	border-radius: 50%;
	bottom: 3px;
	content: "";
	display: block;
	left: 3px;
	position: absolute;
	right: 3px;
	top: 3px;
	opacity: 0;
	visibility: hidden;
	z-index: 2;
	transition-property: opacity;
	transition-duration: 0.4s;
	transition-timing-function: linear;
	transition-delay: 0;
}

.marshall-newsletter-content.mform-animate .marshall_submit::before
{
	opacity: 1;
	visibility: visible;
	transition: top 0.4s, left 0.4s, right 0.4s, bottom 0.4s, border-radius 0.4s, border-width 0.4s ease 0.4s;
	animation: 1s linear 0.4s normal none infinite running spinnerRotate;
}

.marshall-newsletter-content.mform-submitting.mform-success .marshall_submit::after
{
	transform: scale(1);
	opacity: 1;
	visibility: visible;
}

.marshall-newsletter-content.mform-animate.mform-submitting.mform-success .marshall_submit::before
{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-width: 0;
}


@keyframes mformBounceInDown
{
	from, 60%, 75%, 90%, to
	{
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}

	0%
	{
		opacity: 0;
		transform: translate3d(0, -50px, 0);
	}

	60%
	{
		opacity: 1;
		transform: translate3d(0, 15px, 0);
	}

	75%
	{
		transform: translate3d(0, -10px, 0);
	}

	90%
	{
		transform: translate3d(0, 5px, 0);
	}

	to
	{
		transform: none;
	}
}

.mformBounceInDown
{
	animation-name: mformBounceInDown;
}

.marshall-newsletter-header.mfrom-header-animate-close img
{
	animation-name: mformBounceInDown;
	animation-duration: 0.6s;
	animation-timing-function: linear;
	animation-delay: 0.4s;
	animation-iteration-count: 1;
	animation-direction: alternate;
}


@media (min-width: $max-width-s)
{
	.marshall-newsletter-header
	{
		img
		{
			width: 100px;
		}
	}
}