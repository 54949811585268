/* -----------------------------------------------------------------------------

# Components - List

----------------------------------------------------------------------------- */

ol,
ul {
  margin: 0 0 $space 0;
  padding-left: 1.25rem;

  &.blank {
    list-style: none;
    padding: 0;
  }

  &.inline {
    padding-left: 0;

    li {
      display: inline-block;
      padding-right: $space;
      vertical-align: top;

      &:last-child {
        padding-right: 0;
      }
    }
  }
}