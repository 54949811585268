/* -----------------------------------------------------------------------------

# Tools - Opacity

----------------------------------------------------------------------------- */

.opacity-s {
  opacity: $opacity-s !important;
}

.opacity-m {
  opacity: $opacity-m !important;
}

.opacity-l {
  opacity: $opacity-l !important;
}