/* -----------------------------------------------------------------------------

# Components - Card

----------------------------------------------------------------------------- */

.card {
  @include rounded(0.5rem);
  @include shadow-s;
  background: $color-white;

  &.dark {
    background: lighten($color-text, $lighten);
  }
}

.card-content {
  padding: 2 * $space;
  
  @include mq("mobile") {
    padding: 1.5 * $space;
  }
}

.card-content-thin {
  padding: 1 * $space 1.5 * $space;
}

.card-aroc{
  position: relative;
}

.card-aroc > img{
  height: 60%;
}

.card-aroc > div {
  position: absolute;
  left: 0;
  right: 0;
}