/* -----------------------------------------------------------------------------

# Components - Icons

----------------------------------------------------------------------------- */

.feature-icons {
  @include rounded(100%);
  background: rgba($color-text, 0.04);
  color: $color-text;
  font-size: 3rem;
  height: 6rem;
  line-height: 6rem;
  margin-bottom: $space;
  text-align: center;
  width: 6rem;

  .dark & {
    background: rgba($color-white, $opacity-l);
    color: $color-white;
  }

  .preload & {
    font-size: 0;
  }
}