// Colors

$color-white       : #fff;
$color-black       : #2d2d2d;
$color-primary     : #C01F2F;
$color-secondary   : #75030E;
$color-text        : #1a0f11;
$color-gray-light  : #f6f6f9;
$color-gray-medium : #ececee;
$color-gray-dark   : #ccced0;
$color-red         : #e52b20;
$color-green       : #63ce63;
$color-orange      : #ffbf2f;

// Fonts

$font-sans  : "Lato", Helvetica, sans-serif;
$font-serif  : "PT Serif", Georgia, serif;

// Maximum widths

$max-width-s : 400px;
$max-width-m : 800px;
$max-width-l : 1200px;

// Opacity

$opacity-s : 0.9;
$opacity-m : 0.4;
$opacity-l : 0.1;

// Spacing betwenn elements

$space : 1rem;

// Darken and lighten colors

$darken  : 7%;
$lighten : 7%;