/* -----------------------------------------------------------------------------

# Components - Pricing

----------------------------------------------------------------------------- */

.pricing-price {
  font-weight: 400;
}

.pricing-duration {
  @include font-size-s;
}