.morph-button
{
	position: relative;
	display: block;
	margin: 0 auto;
}

.morph-button.open > button
{
	pointer-events: none;
}

.morph-content
{
	pointer-events: none;
}

.morph-button.open .morph-content
{
	pointer-events: auto;
}

/* Common styles for overlay and modal type (fixed morph) */
.morph-button-fixed, 
.morph-button-fixed .morph-content
{
	height: 43px;
}

.morph-button-fixed > button
{
	z-index: 1000;
	width: 100%;
	height: 100%;
	transition: opacity 0.1s 0.5s;
}

.morph-button-fixed.open > button
{
	opacity: 0;
	transition: opacity 0.1s;
}

.morph-button-fixed .morph-content
{
	position: fixed;
	z-index: 900;
	opacity: 0;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

.morph-button-fixed.open .morph-content
{
	opacity: 1;
}

.morph-button-fixed .morph-content > div
{
	visibility: hidden;
	height: 0;
	opacity: 0;
	transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
}

.morph-button-fixed.open .morph-content > div
{
	visibility: visible;
	height: auto;
	opacity: 1;
	transition: opacity 0.3s 0.5s;
}

.morph-button-fixed.active > button
{
	z-index: 2000;
}

.morph-button-fixed.active .morph-content
{
	z-index: 1900;
}

/* Transitions for overlay button and sidebar button */
.morph-button-overlay .morph-content,
.morph-button-sidebar .morph-content
{
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
}

.morph-button-overlay.open .morph-content,
.morph-button-sidebar.open .morph-content
{
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
}

/* Morph Button Style: Overlay */
.morph-button.morph-button-overlay
{
	margin: 50px auto;
}

.morph-button-overlay .morph-content
{
	overflow: hidden;
	background: #e85657;
}

.morph-button-overlay.open .morph-content
{
	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;
}

/* Morph Button Style: Modal */
.morph-button-modal::before
{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 800;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	content: '';
	opacity: 0;
	transition: opacity 0.5s;
	pointer-events: none;
}

.morph-button-modal.open::before
{
	opacity: 1;
	pointer-events: auto;
}

.morph-button-modal.active::before
{
	z-index: 1800;
}

.morph-button-modal .morph-content
{
	overflow: hidden;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, border-radius 0.4s 0.1s;
}

.morph-button-modal.open .morph-content
{
	top: 50% !important;
	left: 50% !important;
	margin: -210px 0 0 -300px;
	width: 600px !important;
	height: 420px;
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s, border-radius 0.4s 0.1s;
	max-width: 100%;
}


.morph-button.morph-button-modal
{
	display: inline-block;
	margin: 0;
}

.morph-button-modal .morph-content
{
	background-color: #fff;
	color: #000;
}

.morph-button-modal.open .morph-content > div
{
	transition: opacity 0.3s 0.3s;
}



/* Morph Button Style: Sidebar */
.morph-button-sidebar,
.morph-button-sidebar .morph-content
{
	width: 60px;
	height: 60px;
}

.morph-button-sidebar
{
	position: fixed;
	bottom: 50px;
	left: 50px;

	> button
	{
		line-height: 60px;
		font-size: 1.6em;
		padding: 0;
	}
}

.morph-button-sidebar .morph-content
{
	background: #e85657;
}

.morph-button-sidebar.open .morph-content
{
	top: 0 !important;
	left: 0 !important;
	width: 300px !important;
	height: 100%;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

/* Let's add some nice easing for all cases */
.morph-button .morph-content,
.morph-button.open .morph-content
{
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}


/* Helper classes */
.noscroll
{
	overflow: hidden;
}

.morph-button-overlay.scroll .morph-content
{
	overflow-y: scroll;
}

.morph-button-sidebar.scroll .morph-content
{
	overflow: auto;
}

.morph-button-modal.open .morph-content
{
	margin-left: -150px !important;
	width: 300px !important;
}


@media (min-width: $max-width-s)
{
	.morph-button-modal.open .morph-content
	{
		height: 464px;
		margin-top: -232px;
		top: 50% !important;
		left: 50% !important;
		transform: none !important;
		margin-left: -220px !important;
	}

	.morph-button-modal.open .morph-content
	{
		margin: -210px 0 0 -170px;
		width: 440px !important;
		height: 420px;
		max-width: 100%;
	}
}