/*!
 *
 *   Template Name: Opalin HTML Template
 *   Template URI: https://uiuxassets.com/assets/opalin-html-template/
 *   Author: UI/UX Assets <hello@uiuxassets.com>
 *   Author URI: https://uiuxassets.com/
 *   Version: v1.0.0
 *   License: GNU General Public License, Version 2
 *   License URI: http://www.gnu.org/licenses/gpl-2.0.html
 *   Copyright (C) 2018 UI/UX Assets
 *
*/

/* -----------------------------------------------------------------------------

Table Of Contents

1.0 - Base
  1.1 - Reset
  1.2 - Typography
  1.3 - Grid

2.0 - Tools
  2.1 - Align
  2.2 - Background
  2.3 - Divider
  2.4 - Full Screen
  2.5 - Full Width
  2.6 - Max Width
  2.7 - Min Width
  2.8 - Opacity
  2.9 - Rounded
  2.10 - Shadow
  2.11 - Spacing

3.0 - Components
  3.1 - Alerts
  3.2 - Buttons
  3.3 - Card
  3.4 - Footer
  3.5 - Forms
  3.6 - Header
  3.7 - Icons
  3.8 - List
  3.9 - Logo
  3.10 - Pricing
  3.11 - Table

4.0 - Pages
  4.1 - Onboarding

----------------------------------------------------------------------------- */

@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=PT+Serif:400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Base

@import "base/variables";
@import "base/mixins";
@import "base/reset";
@import "base/typography";
@import "base/grid";

// Tools

@import "tools/align";
@import "tools/background";
@import "tools/divider";
@import "tools/full-screen";
@import "tools/full-width";
@import "tools/max-width";
@import "tools/min-width";
@import "tools/opacity";
@import "tools/rounded";
@import "tools/shadow";
@import "tools/spacing";

// Components

@import "components/alerts";
@import "components/buttons";
@import "components/card";
@import "components/footer";
@import "components/forms";
@import "components/header";
@import "components/icons";
@import "components/list";
@import "components/logo";
@import "components/pricing";
@import "components/table";

// Pages

@import "pages/onboarding";


@import 'invite/morph-button';
@import 'invite/invite-form';
@import 'invite/animation-icons';


button
{
	font: inherit;
	background: none;
	color: inherit;
	border: none;
	outline: none;
	cursor: pointer;
}

.linkedin{
	height: 1.5rem;
	float: right;
	margin-right: 0;
	filter: grayscale(1);
}

.linkedin:hover {
  filter: grayscale(0);
}

.partners
{
	margin-top: 1rem;
	
	.col-one-fourth, .col-one-fifth, .col-one-sixth
	{
		display: flex;
		align-items: center;
		
		img
		{
			padding: 0.5em;
			margin:0 auto;
		}
		
		a
		{
			margin: 0 auto;
		}
	}
	
	.col-one-sixth
	{
		img
		{
			padding: 0.1em;
		}
	}
}

.products 
{
	.card-content  
	{
		display:flex;
		flex-direction:column;
		align-items:center;
		
		a 
		{
			margin-top:auto;
    }
  }
}

.header-main ul li
{
	position:relative;
	
	& > ul
	{
		padding:0;
		margin:0;
	}
	@include mq("tablet", min)
	{
		& > ul 
		{
			display:none;
			position:absolute;
			top:100%;
			left:0;
		}
		&:hover > ul 
		{
			display:inherit;
		}
		&:hover > a 
		{
			color:#C01F2F;
			opacity:1;
		}
		& > ul li 
		{
			display:block;
		}
		& > ul li a 
		{
			white-space:nowrap;
		}
	}
	@include mq("tablet")
	{
		&.mobile-hide > a 
		{
			display: none;
		}
	}
}


.mobile-hide 
{
	@include mq("tablet")
	{
		display:none;
	}
}
.mobile-show
{
	display:none;
	&:after {
		content:'';
		display:block;
		clear:both;
	}
	@include mq("tablet")
	{
		display:block;
	}
}


.header-main.active ul.inline li.mobile-hide
{
	@include mq("tablet")
	{
		margin-bottom:0;
	}
}

.hidden 
{
	display:none;
}

.request-demo 
{	
	.dark.overlay-shape-03:before 
	{
		background:linear-gradient(#1a0f11 0%, #030202 100%);
	}
	.checkbox label 
	{
		display:block;
	}
	form label.checkbox
	{
		display:inline-block;
		margin-right:0.2em;				
	}
	.checkbox br 
	{
		display:none;
	}
	::-webkit-input-placeholder
	{
		color:#1a0f11;
	}
	:-moz-placeholder
	{
		color:#1a0f11;
		opacity:1;
	}
	::-moz-placeholder
	{
		color:#1a0f11;
		opacity:1;
	}
	:-ms-input-placeholder
	{
		color:#1a0f11;
	}
	@media only screen and (max-width: 767px)
	{
		.row.max-width-l 
		{
			flex-direction:column-reverse;
		}
		.space-left 
		{
			padding-left:0;
			margin-bottom:3rem;
		}
		.space-right 
		{
			padding-right:0;
		}	
		.checkbox  
		{
			text-align:left;
			
			br 
			{
				display:block;
			}
		}
    }
}


#popup-invite
{
	z-index: 1800;
	font-size: 1rem;
	line-height: 1.25rem;
}

.morph-button > button
{
	font-weight: 700;
}

.morph-button-fixed, .morph-button-fixed .morph-content
{
	@include mq("mobile")
	{
		height: 3.5rem;
		line-height: 3.5rem;
	}
	
	&.open
	{
		border-radius: 3rem;
	}
}

body > header > nav
{
	@include mq("mobile")
	{
		.morph-button-fixed
		{
			width: 100%;
		}
	}
}

.marshall-newsletter-header
{
	user-select: none;
}

.morph-content
{
	button
	{
		outline: none;
	}
}

.morph-content
{
	border-radius: 3em;
}

.morph-button.open .morph-content,
.morph-content button,
.morph-content input
{
	border-radius: 4px;
}

body > header.header-main
{
	z-index: 1003;
}


.embed-container
{
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	box-shadow: 0 0 5em rgba(255,255,255,0.5);
}

.embed-container iframe,
.embed-container object,
.embed-container embed
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

svg+span.tm
{
	font-size: 0.75rem;
	margin-left: -0.5em;
	margin-top: -0.5em;
}

span.tm
{
	font-size: 0.75rem;
	margin-top: -0.5em;
}

h1 span.tm
{
	font-size: 1.25rem;
	position: absolute;
}

body > .header-main a.menu-phone
{
	opacity: 0.7;
}

body > .header-main.dark a.menu-phone:hover
{
	opacity: 1.0;
}

.row.min-two-columns:not(.reverse-order)
{
	@include mq("desktop-s")
	{
		[class^="col-"]
		{
			margin: (1 * $space) ($gutter / 2) 0 ($gutter / 2);
			
			&:last-child:nth-child(odd)
			{
				width: 50%;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.width-200
{
	width: 200px;
}


/* animation for Magic Leap's lovely heart icon */
.leap-love
{
    width: 0.7em;
    height: 0.7em;
    fill: rgb(241, 39, 66);
    pointer-events: none;
    margin: -4px 6px 0px;
    animation: myheartgrows 2s ease-in-out 0s infinite normal none running;
}

@keyframes myheartgrows
{
	0%, 25%, 50%, 75%, 100% { transform: scale3d(1, 1, 1); }
	40%, 60% { transform: scale3d(1.2, 1.2, 1.2); }
}

*
{
	font-face: swap;
}