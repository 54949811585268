/* -----------------------------------------------------------------------------

# Tools - Rounded

----------------------------------------------------------------------------- */

.rounded {
  @include rounded(0.5rem);

  &-bottom {
    @include rounded-bottom(0.5rem);
  }

  &-top {
    @include rounded-top(0.5rem);
  }
}