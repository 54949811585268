.animation-icon
{
	color: #e2e2e4;
	left: 0;
	position: absolute;
	top: 0;
	transition: all 0.6s ease 0.0s;

	img
	{
		width: 1em;
	}
}

.medium-icon { font-size: 1.5em; }
.small-icon  { font-size: 1.2em; }
.large-icon  { font-size: 2.0em; }

.marshall-newsletter-animation-icons {
  height: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.marshall-newsletter-content label {
  display: block;
  font-size: 0.8em;
  margin-top: 5px;
}
.request-demo.open-animate .animation-icon-7,
.mfrom-header-animate .animation-icon-7 {
  left: -175px;
  top: -12px;
  transform: rotate(-20deg);
}
.request-demo.open-animate .animation-icon-8,
.mfrom-header-animate .animation-icon-8 {
  left: -175px;
  top: 40px;
  transform: rotate(-7deg);
}
.request-demo.open-animate .animation-icon-4,
.mfrom-header-animate .animation-icon-4 {
  left: -125px;
  top: 22px;
  transform: rotate(-4deg);
}
.request-demo.open-animate .animation-icon-1,
.mfrom-header-animate .animation-icon-1 {
  left: -94px;
  top: -19px;
  transform: rotate(20deg);
}
.request-demo.open-animate .animation-icon-2,
.mfrom-header-animate .animation-icon-2 {
  left: -94px;
  top: 51px;
  transform: rotate(-20deg);
}
.request-demo.open-animate .animation-icon-9,
.mfrom-header-animate .animation-icon-9 {
  left: -71px;
  top: 8px;
  transform: rotate(22deg);
}
.request-demo.open-animate .animation-icon-16,
.mfrom-header-animate .animation-icon-16 {
  left: 185px;
  top: -12px;
  transform: rotate(20deg);
}
.request-demo.open-animate .animation-icon-17,
.mfrom-header-animate .animation-icon-17 {
  left: 185px;
  top: 40px;
  transform: rotate(7deg);
}
.request-demo.open-animate .animation-icon-5,
.mfrom-header-animate .animation-icon-5 {
  left: 148px;
  top: 22px;
  transform: rotate(4deg);
}
.request-demo.open-animate .animation-icon-3,
.mfrom-header-animate .animation-icon-3 {
  left: 117px;
  top: -19px;
  transform: rotate(-20deg);
}
.request-demo.open-animate .animation-icon-10,
.mfrom-header-animate .animation-icon-10 {
  left: 117px;
  top: 51px;
  transform: rotate(20deg);
}
.request-demo.open-animate .animation-icon-18,
.mfrom-header-animate .animation-icon-18 {
  left: 90px;
  top: 8px;
  transform: rotate(-22deg);
}

.marshall-newsletter-header.mfrom-header-animate-close .marshall-newsletter-animation-icons .animation-icon{
  left: 0;
  transform: rotate(0deg);
  top: 0;
  opacity: 0;
}
