/* -----------------------------------------------------------------------------

# Tools - Full Screen

----------------------------------------------------------------------------- */

.full-screen {
  min-height: calc(100vh);
  width: 100%;
}

@media all and (-ms-high-contrast: none) {
  .full-screen {
    display: block;
    min-height: 0;
    padding-bottom: 8 * $space !important;
    padding-top: 8 * $space !important;
  }
}